<template>
    <b-modal v-if="product" :title="$t('menu.subcategory.deleted_products')" @hide="product = null" size="xl" class="w-50" v-model="modalActive" no-close-on-backdrop>
        <b-container>
            <b-row class="mb-2">
                <b-col>
                    <div class="mb-50 h5">{{ this.$t('general.business_unit') }}</div>
                    <div>{{ product.business_unit.name }}</div>
                </b-col>
                <b-col>
                    <div class="mb-50 h5">{{ this.$t('reporting.archived.cash_register_id') }}</div>
                    <div>{{ product.cash_register.cash_register_id }}</div>
                </b-col>
                <b-col>
                    <div class="mb-50 h5">{{ this.$t('general.employees') }}</div>
                    <div>{{ product.employee.name }}</div>
                </b-col>
            </b-row>
            <b-row class="mb-2">
                <b-col>
                    <div class="mb-50 h5">{{ this.$t('reporting.archived.total_price') }}</div>
                    <div>{{ parseFloat(product.total_price / 1000000).toFixed(2) + ' €' }}</div>
                </b-col>
                <b-col>
                    <div class="mb-50 h5">{{ this.$t('general.deleted') }}</div>
                    <div>{{ dayjs(product.deleted_timestamp).format('DD.MM.YYYY ob H:mm:ss') }}</div>
                </b-col>
                <b-col></b-col>
            </b-row>
        </b-container>

        <div class="mt-4 h3">{{ this.$t('reporting.archived.products') }}</div>

        <BasicTable
            :data="products"
            :columns="columns"
            v-slot="props"
            class="mt-4"
        >

            <div v-if="props.column.displayType === 1" class="d-flex justify-content-center">
                <ul>
                    <li v-for="option in props.row.selected_options" :key="option._id">
                        <span v-if="option.quantity < 0">
                            <span class="text-danger">{{ this.$t('reporting.archived.without') }}</span>
                            {{ option.name }}
                        </span>
                        <span v-else>
                            {{ option.name + '(' + option.quantity + 'x)' }}
                        </span>
                    </li>
                </ul>
            </div>
            <div v-else-if="props.column.displayType === 2" class="d-flex justify-content-center">
                <ul>
                    <li v-for="option in props.row.additional_options" :key="option">
                        {{option}}
                    </li>
                </ul>
            </div>
            <div v-else-if="props.column.displayType === 3">
                {{ product.bill_uuid }}
            </div>
        </BasicTable>
    </b-modal>
</template>

<script>
    import {BModal, BContainer, BRow, BCol} from 'bootstrap-vue'
    import BasicTable from '@/views/components/BasicTable.vue'


    export default {
        components: {
            BModal,
            BasicTable,
            BContainer,
            BRow,
            BCol
        },
        data() {
            return {
                products: [],
                product: null,
                modalActive: false
            }
        },
        computed: {
            columns() {
                return [
                    {
                        label: this.$t('table_fields.deleted'),
                        displayType: 0,
                        field: row => this.getTimeStamp(row)

                    },
                    {
                        label: this.$t('table_fields.uuid'),
                        displayType: 0,
                        field: 'product.product_code'
                    },
                    {
                        label: this.$t('table_fields.name'),
                        displayType: 0,
                        field: 'product.name'
                    },
                    {
                        label: this.$t('table_fields.category'),
                        displayType: 0,
                        field: 'product.product_category.name'
                    },
                    {
                        label: this.$t('table_fields.tax_rate'),
                        displayType: 0,
                        field: row => this.getVat(row)
                    },
                    {
                        label: this.$t('table_fields.original_price'),
                        displayType: 0,
                        field: row => this.getOriginalPrice(row)
                    },
                    {
                        label: this.$t('table_fields.quantity'),
                        displayType: 0,
                        field: row => this.getQuantity(row)
                    },
                    {
                        label: this.$t('table_fields.discount_percentage'),
                        displayType: 0,
                        field: row => this.getDiscount(row)
                    },
                    {
                        label: this.$t('table_fields.total_price'),
                        displayType: 0,
                        field: row => this.getTotalPrice(row)
                    },
                    {
                        label: this.$t('table_fields.notes'),
                        displayType: 0,
                        field: 'notes'
                    },
                    {
                        label: this.$t('table_fields.selected_options'),
                        displayType: 1,
                        field: this.getSelectedOptionString
                    }
                ]
            }
        },
        methods: {
            open(data = null) {
                if (data !== null) {
                    this.products = data.products
                    this.product = data
                }
                this.modalActive = true
            },
            getDiscount(row) {
                if (row) {
                    return `${row.discount / 100} %`
                } else { return '/' }
            },
            getOriginalPrice(row) {
                if (row) {
                    return `${this.$numberRounding(row.original_price, 2)} €`
                } else { return '/' }
            },
            getTotalPrice(row) {
                if (row) {
                    return `${this.$numberRounding(row.total_price, 2)} €`
                } else { return '/' }
            },
            getSelectedOptionString(row = null) {
                if (row) {
                    return row.selected_options.map(option => `${option.name}(${option.quantity}x)`).join(', ')
                } else {
                    return '/'
                }
            },
            getQuantity(row) {
                if (row) {
                    if (row.quantity % 100 !== 0) {
                        return parseFloat(row.quantity / 100).toFixed(2)
                    } else {
                        return parseFloat(row.quantity / 100).toFixed(0)
                    }
                } else { return '/' }
            },
            getTimeStamp(row) {
                if (row) {
                    return this.dayjs(row.timestamp).format('DD.MM.YYYY HH:mm:ss')
                } else { return '/' }
            },
            getVat(row) {
                if (row) {
                    return `${row.product.vat_group.percentage / 100} %`
                } else { return '/' }
            }

        }
    }
</script>

<style scoped>

</style>